var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{staticClass:"pb-3",attrs:{"title":_vm.title}}),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"formatosTable","headers":_vm.formatosHeader,"header-props":{ sortIcon: null },"items":_vm.formatosEmailActas,"search":_vm.search,"item-key":"emailId","sort-desc":_vm.sortDesc,"footer-props":{
            'items-per-page-options': _vm.itemsPerPageOptions
          },"items-per-page":_vm.itemPerPage},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.activo",fn:function(ref){
          var item = ref.item;
return [(item.activo)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.check)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":_vm.newFormato}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.editFormato(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar formato")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openDeleteFormat(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar formato")])]):_vm._e()]}}],null,true)})],1)],1),_c('v-col',{staticClass:"to-right pt-7 px-0 ",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1),_c('DeleteDialog',{attrs:{"openDelete":_vm.openDeleteDialog},on:{"update:openDelete":function($event){_vm.openDeleteDialog=$event},"update:open-delete":function($event){_vm.openDeleteDialog=$event},"onDeleteItem":function($event){return _vm.deleteFormat()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }